import React from "react";
import { GatsbyImage } from "gatsby-plugin-image"

const Header = ({ image }) => (
  <div className="about section" id="About">
    <div className="container">
      <div className="about-main row">
        <div className="left col-md-5 col-lg-4 mb-3">
          <GatsbyImage
            image={image}
            objectFit="cover"
            objectPosition="top center"
          />
        </div>
        <div className="left col-md-7 col-lg-8">
          <div className="about-details">
            <span className="name">Alumiah Inc.</span>
            <h2 className="sub-position">
              Specializing in software development and data services.
            </h2>
            <div class="pt-2">
              We build custom software solutions and provide consulting services.
              <ul>
                <li>Website Development</li>
                <li>Data Transformation, Support, Analysis</li>
                <li>Database Support: PostgreSQL, MySQL, Oracle, more</li>
                <li>Programming: Python, Javascript, PHP, C#, more</li>
              </ul>
            </div>
            <ul className="details">
              <li>
                <strong>Location</strong>
                <p>Minneapolis, Minnesota, USA</p>
              </li>
              <li>
                <strong>Email</strong>
                <p>info@alumiah.com
                </p>
              </li>
            </ul>

          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Header;
