import React, { Component } from "react";
import { GatsbyImage } from "gatsby-plugin-image"

export default class Banner extends Component {
  render() {
    const { image } = this.props;
    return (
      <div className="banner">     
        <GatsbyImage
          image={image}
          objectFit="cover"
          objectPosition="50% 50%"
        />
        <div className="container">
          <div className="banner-details">
            <span>Alumiah Inc.</span>
            <h1>Software development.</h1>
            <ul className="sub-data">
              <li key="custom-programming">Custom Programming</li>
              <li key="data-services">Data Services</li>
              <li key="custom-solutions">Custom Solutions</li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
