import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

import Banner from "../components/banner";
import About from "../components/about";
import Contact from "../components/contact";

const IndexPage = ({ data : { images } }) => {

  var imagearr = []
  for (var img of images.edges) {
    imagearr[img.node.name] = img.node.childImageSharp.gatsbyImageData
    //console.log('image name='+img.node.name);
  }

  return (
  <Layout header="home">
    <SEO
      title="Alumiah Inc."
      keywords={[`Alumiah`, `Software Development`, `Programming`, `Data`]}
    />
    <Banner image={imagearr["background1"]}></Banner>
    <About image={imagearr["computer-code"]}></About>

  </Layout>
  );
};

export default IndexPage

export const indexPageQuery = graphql`
  query indexPageImages {
    images: allFile (filter: { sourceInstanceName: { eq: "index-page-images" }}) {
      edges {
        node {
          dir
          ext
          sourceInstanceName
          name
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`

