import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import "bootstrap/dist/css/bootstrap.css";

import Header from "./header";
import Footer from "./footer";

import "../css/style.css";
import "../css/font-awesome.css";

if (typeof window !== "undefined") {
  require("smooth-scroll")('a[href*="#"]');
}

const Layout = ({ children, header }) => {
  return (
      <>
        <Header
          siteTitle="Alumiah Inc."
          header={header}
        />
        <div>
          <main id="home">{children}</main>
        </div>
        <Footer siteName="Alumiah Inc." />
      </>
    )
  }


Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
